import React from "react"
import { Formik } from "formik"

const EmailForm = () => {
  const submitRequest = async values => {
    try {
      const response = await fetch(
        "https://api.americandream.com/1/subscriptions/",
        {
          method: "POST",
          body: JSON.stringify(values),
        }
      )
      return await response.json()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div
      className="bg-white py-8 px-20 text-black signup-form"
      style={{ borderRadius: "2em" }}
    >
      <span className="email-form-title">
        <h2 className="font-bold text-3xl md:text-5xl">Get in the know.</h2>
        <p className="mb-12 text-xl tracking-wide">
          Sign up to receive the latest & greatest email updates on all things
          retail.
        </p>
      </span>
      <Formik
        initialValues={{ email: "", firstName: "", lastName: "", zipCode: "" }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Email required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          values.group = "newsletter"
          let submission = await submitRequest(values)
          if (submission.message == "success") {
            document.querySelector("form").remove()
            document.querySelector(
              ".email-form-title h2"
            ).textContent = `Thank you for subscribing!`
            document.querySelector(
              ".email-form-title p"
            ).textContent = `Your subscription to our list has been confirmed.`
          } else {
            alert(
              "There was an error with your form submission. Please try again in a moment."
            )
          }
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2))
          //   setSubmitting(false)
          // }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="field relative p-2 border rounded-md mb-6">
              <label
                htmlFor="email"
                className="text-sm uppercase tracking-widest bg-white px-1 absolute"
                style={{ top: "-0.75em", left: "1em" }}
              >
                Email
              </label>
              <div className="input relative">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="w-full border-none py-2 px-1 font-light"
                />
              </div>
            </div>
            <div className="field relative p-2 border rounded-md mb-6">
              <label
                htmlFor="firstName"
                className="text-sm uppercase tracking-widest bg-white px-1 absolute"
                style={{ top: "-0.75em", left: "1em" }}
              >
                First Name
              </label>
              <div className="input relative">
                <input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  className="w-full border-none py-2 px-1 font-light"
                />
              </div>
            </div>
            <div className="field relative p-2 border rounded-md mb-6">
              <label
                htmlFor="lastName"
                className="text-sm uppercase tracking-widest bg-white px-1 absolute"
                style={{ top: "-0.75em", left: "1em" }}
              >
                Last Name
              </label>
              <div className="input relative">
                <input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  className="w-full border-none py-2 px-1 font-light"
                />
              </div>
            </div>
            <div className="field relative p-2 border rounded-md mb-12">
              <label
                htmlFor="zipCode"
                className="text-sm uppercase tracking-widest bg-white px-1 absolute"
                style={{ top: "-0.75em", left: "1em" }}
              >
                Zip Code
              </label>
              <div className="input relative">
                <input
                  type="text"
                  name="zipCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipCode}
                  className="w-full border-none py-2 px-1 font-light"
                  autoComplete="postal-code"
                />
              </div>
            </div>
            <div className="text-red-500">{errors.email}</div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-20 py-3 uppercase text-white bg-black rounded-full disabled`}
            >
              Subscribe
            </button>
          </form>
        )}
      </Formik>
      <div className="message"></div>
    </div>
  )
}

export default EmailForm
