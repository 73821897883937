import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CalBtn from "../components/CalBtn"
import EmailForm from "../components/EmailForm"
const moment = require("moment-timezone")

const IndexPage = () => {
  const now = moment.tz(new Date(), "DD", "EST")
  const currDay = now.date()
  const currHour = now.date()
  // const currDay = 19
  // const currHour = 20
  const events = {
    event1: {
      title: "EXPLORE: MUSIC",
      description: `Catch local DJs spinning tunes all weekend long, sponsored by Columbia and Skechers.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event2: {
      title: "EXPLORE: FASHION",
      description: `Watch your favorite looks of the season come to life at American Dream’s first trend parade! Looks from Aritzia, Century 21, Columbia, UNIQLO, and more will be styled in an immersive, moving presentation curated by Ken Downing, Chief Creative Officer of Triple Five Group. Located throughout American Dream retail atriums.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event3: {
      title: "EXPLORE: BEAUTY",
      description: `Discover exotic eye makeup looks from beauty brands <a href="https://morphe.com" target="_blank" class="underline">Morphe</a> and <a href="https://www.maccosmetics.com/" target="_blank">MAC</a>! Located in Court D.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event4: {
      title: "EXPLORE: FUN",
      description: `Swing into Spring by Nickelodeon Universe ticketing on bamboo swings and overlook the park with bamboo telescopes. Explore your inner child in the inner tube igloos located by the entrance to DreamWorks Water Park. Don’t forget to capture your moments as you explore American Dream at our photo booths! Located throughout American Dream.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event5: {
      title: "MAKE A SPLASH",
      description: `Explore the world’s only DreamWorks Water Park at American Dream! Dive into the world’s largest indoor wave pool and surf with Shrek! Tickets available now at <a href="https://americandream.com/venue/dreamworks-water-park" class="underline" target="_blank">www.americandream.com/venue/dreamworks-water-park</a>. Located at the DreamWorks Water Park.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event6: {
      title: "STACY LONDON FLEA",
      description: `Join fashionista <a href="https://www.instagram.com/small_beautiful_things_ny/" target="_blank" class="underline">Stacy London</a> at her first-ever Small Beautiful Things flea market in the Garden Court for a chance to purchase covetable, collectible vintage items!`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event7: {
      title: "MOTHER PIGEON",
      description: `Mother Pigeon is a street performance artist who will be bringing her art installation of handmade soft sculptures, representing pigeons and other urban wildlife, to life in the Garden Court!`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event8: {
      title: "GIRL SCOUTS",
      description: `The Girl Scouts will be taking over the Attractions Court hosting mini dance parties with their very own DJ and selling your favorite cookies!`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
    event9: {
      title: "AHA WITH COCA-COLA",
      description: `Discover Coca-Cola’s newest drink, AHA, at American Dream. Quench your thirst with sampling at DreamWorks Water Park and in Court C. The first 150 guests inside DreamWorks Water Park each day between Thursday, March 19 – Sunday, March 22 will receive limited edition Coca-Cola flip flops. March 21 at DreamWorks Water Park & March 22 in Court C.`,
      location: "1 American Dream Way, East Rutherford, NJ 07073",
      startTime: null,
      endTime: null,
    },
  }
  /**
   * @switch currDay
   *  cases:
   *    - < 19
   *    - === 19
   *    - 20
   *    - 21
   *    - 22
   */
  console.log(currDay)
  switch (currDay) {
    case 19:
      switch (currHour) {
        case currHour < 18:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-19 15:00", "America/New_York")
            .format()
          events.event1.endTime = moment.tz(
            "2020-03-19 18:00",
            "America/New_York"
          )
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-19 15:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-19 18:00", "America/New_York")
            .format()
          break
        default:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-20 15:00", "America/New_York")
            .format()
          events.event1.endTime = moment
            .tz("2020-03-20 18:00", "America/New_York")
            .format()
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-20 15:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-20 18:00", "America/New_York")
            .format()
          break
      }
      break
    case 20:
      switch (currHour) {
        case currHour < 18:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-20 15:00", "America/New_York")
            .format()
          events.event1.endTime = moment
            .tz("2020-03-20 18:00", "America/New_York")
            .format()
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-20 15:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-20 18:00", "America/New_York")
            .format()
          break
        default:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-21 12:00", "America/New_York")
            .format()
          events.event1.endTime = moment
            .tz("2020-03-21 18:00", "America/New_York")
            .format()
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-21 12:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-21 18:00", "America/New_York")
            .format()
          break
      }
      break
    case 21:
      switch (currHour) {
        case currHour < 18:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-21 15:00", "America/New_York")
            .format()
          events.event1.endTime = moment
            .tz("2020-03-21 18:00", "America/New_York")
            .format()
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-21 15:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-21 18:00", "America/New_York")
            .format()
          break
        default:
          // event 1
          events.event1.startTime = moment
            .tz("2020-03-22 12:00", "America/New_York")
            .format()
          events.event1.endTime = moment
            .tz("2020-03-22 18:00", "America/New_York")
            .format()
          // event 2
          events.event2.startTime = moment
            .tz("2020-03-22 12:00", "America/New_York")
            .format()
          events.event2.endTime = moment
            .tz("2020-03-22 18:00", "America/New_York")
            .format()
          break
      }
    case currDay >= 22:
      // event 1
      events.event1.startTime = moment
        .tz("2020-03-22 12:00", "America/New_York")
        .format()
      events.event1.endTime = moment
        .tz("2020-03-22 18:00", "America/New_York")
        .format()
      // event 2
      events.event2.startTime = moment
        .tz("2020-03-22 12:00", "America/New_York")
        .format()
      events.event2.endTime = moment
        .tz("2020-03-22 18:00", "America/New_York")
        .format()
      break
    default:
      // event 1
      events.event1.startTime = moment
        .tz("2020-03-19 15:00", "America/New_York")
        .format()
      events.event1.endTime = moment
        .tz("2020-03-19 18:00", "America/New_York")
        .format()
      // event 2
      events.event2.startTime = moment
        .tz("2020-03-19 15:00", "America/New_York")
        .format()
      events.event2.endTime = moment
        .tz("2020-03-19 18:00", "America/New_York")
        .format()
      break
  }
  const event3 = {}
  // const events = [event1, event2, event3]
  useEffect(() => {
    const bookBtns = document.querySelectorAll(
      ".react-add-to-calendar__wrapper"
    )
    bookBtns.forEach((btn, idx) => {
      btn.addEventListener("click", function() {
        if (typeof window.fbq !== "undefined") {
          window.fbq("trackCustom", "AddToCalendar", {
            event: events[idx].title,
          })
        }
      })
    })
    // window.snaptr("init", "f54cd204-1fe3-42b0-b98a-e8c2f2ac8155", {
    //   user_email: null,
    // })
    // window.snaptr("track", "PAGE_VIEW")
  }, [])
  return (
    <Layout>
      <SEO title="American Dream Events" />
      <div className="mx-auto">
        <img
          src="/Retail_Banner_640.png"
          alt=""
          className="z-10 mx-auto w-full md:hidden"
        />
        <img
          src="/Retail_Banner_1024.png"
          alt=""
          className="z-10 mx-auto w-full hidden md:block lg:hidden"
        />
        <img
          src="/Retail_Banner_2000.png"
          alt=""
          className="z-10 mx-auto w-full hidden lg:block"
        />
      </div>
      <div className="max-w-5xl mx-auto flex flex-col items-center justify-center text-white mb-20">
        <div className="max-w-full md:max-w-xl text-center flex flex-col items-center justify-center z-20 -mt-12 sm:-mt-20 md:-mt-24 lg:-mt-48 px-4 md:px-0">
          <h2
            className="font-bold uppercase text-3xl md:text-4xl mb-8"
            style={{ textShadow: "0px 0px 26px rgba(255, 255, 255, 0.65)" }}
          >
            EXPLORE THE UNBELIEVABLE AT AMERICAN DREAM
          </h2>
          <p className=" font-light mb-4">
            This spring explore the unbelievable at American Dream with fun
            activities for the entire family including a fashion parade, makeup
            demos and DJs in common areas, immersive tropical installations and
            more!
          </p>
          <p className="font-light mb-12">
            Follow our Instagram and the tag{" "}
            <span classname="underline">#ExploreAD2020</span> for the latest
            updates.
          </p>
          <p className="mb-20">
            <a
              href="https://www.instagram.com/americandream/?hl=en"
              target="_blank"
              className="uppercase bg-transparent border-white border-2 rounded-full px-12 py-3 text-sm tracking-wider"
              style={{ boxShadow: "0px 0px 26px rgba(255, 255, 255, 0.3)" }}
            >
              Follow us
            </a>
          </p>
          <h2
            className="font-bold uppercase text-3xl md:text-4xl mb-4"
            style={{ textShadow: "0px 0px 26px rgba(255, 255, 255, 0.65)" }}
          >
            Retail hours:
          </h2>
          <p className="text-2xl mb-2 uppercase font-semibold">
            <span style={{ color: "#FC02B5" }}>Monday-Saturday</span> | 10AM -
            9:30PM
          </p>
          <p className="text-2xl mb-20 uppercase font-semibold">
            <span style={{ color: "#FC02B5" }}>Sunday</span> | 11AM - 6PM
          </p>
          <h2
            className="font-bold uppercase text-3xl md:text-4xl mb-6"
            style={{ textShadow: "0px 0px 26px rgba(255, 255, 255, 0.65)" }}
          >
            Events:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 ">
            {/* Event 1 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-1.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event1.title}</p>
              <p className="font-base text-sm">Thursday & Friday | 3PM - 6PM</p>
              <p className="font-base text-sm mb-2">
                Saturday & Sunday | 12PM - 6PM
              </p>
              <p
                className="font-light mb-4 text-sm"
                dangerouslySetInnerHTML={{ __html: events.event1.description }}
              ></p>
              <CalBtn event={events.event1} />
            </div>

            {/* Event 2 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-2.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event2.title}</p>
              <p className="font-base text-sm mb-2">Saturday | 2PM & 4PM</p>
              <p
                className="font-light mb-4 text-sm"
                dangerouslySetInnerHTML={{ __html: events.event2.description }}
              ></p>
              <CalBtn event={events.event2} />
            </div>

            {/* Event 3 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event3.title}</p>

              <p className="font-base text-sm">Thursday & Friday | 3PM - 6PM</p>
              <p className="font-base text-sm mb-2">
                Saturday & Sunday | 12PM - 6PM
              </p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event3.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event3} />
            </div>

            {/* Event 4 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event4.title}</p>
              <p className="font-base text-sm mb-2">Thursday through Spring</p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event4.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event4} />
            </div>

            {/* Event 5 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event5.title}</p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event5.description,
                  }}
                ></p>
              </p>
            </div>

            {/* Event 6 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event6.title}</p>
              <p className="font-base text-sm mb-2">
                Thursday - Sunday | 11AM - 6PM
              </p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event6.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event6} />
            </div>

            {/* Event 7 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event7.title}</p>
              <p className="font-base text-sm mb-2">
                Thursday - Sunday | 2PM - 6PM
              </p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event7.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event7} />
            </div>

            {/* Event 8 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event8.title}</p>
              <p className="font-base text-sm mb-2">Saturday & Sunday</p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event8.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event8} />
            </div>

            {/* Event 9 */}
            <div className="" style={{ justifySelf: "center" }}>
              <img src="/event-3.png" alt="" />
            </div>
            <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
              <p className="font-semibold text-lg">{events.event9.title}</p>
              <p className="font-base text-sm">Saturday | 10AM - 4PM</p>
              <p className="font-base text-sm mb-2">Sunday | 11AM - 4PM</p>
              <p className="font-light mb-4 text-sm">
                <p
                  className="font-light mb-4 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: events.event9.description,
                  }}
                ></p>
              </p>
              <CalBtn event={events.event9} />
            </div>
          </div>
          <hr className="glowing-separator" />
          <EmailForm />
          <p className="font-base tracking-wider mt-20 text-left">
            The public safety and health of our guests is a top priority at
            American Dream. We are following the public guidance from the
            Centers for Disease Control and Prevention (CDC) on safe practices
            related to Coronavirus (COVID-19). We are also working closely with
            the Bergen County Department of Health Services and the New Jersey
            Department of Health on all matters relevant to COVID-19. We will
            continue to monitor the CDC and NJDOH guidelines (refer to links
            below) closely and follow their lead if additional action is
            required.
            <br />
            <br />
            New Jersey Department of Health: 
            <a href="https://www.nj.gov/health/" className="underline">
              https://www.nj.gov/health/
            </a>
            <br />
            CDC: 
            <a
              href="https://emergency.cdc.gov/han/han00427.asp"
              className="underline"
            >
              https://emergency.cdc.gov/han/han00427.asp
            </a>
          </p>
          <p
            className="font-base tracking-wider mb-12 mt-20 text-2xl px-20"
            style={{ textShadow: "0px 0px 26px rgba(255, 255, 255, 0.65)" }}
          >
            Follow our Instagram and the tag{" "}
            <span className="underline">#ExploreAD2020</span> for the latest
            updates.
          </p>
          <p className="mb-20">
            <a
              href="https://www.instagram.com/americandream/?hl=en"
              target="_blank"
              className="uppercase bg-transparent border-white border-2 rounded-full px-12 py-3 text-sm tracking-wider"
              style={{ boxShadow: "0px 0px 26px rgba(255, 255, 255, 0.3)" }}
            >
              Follow us
            </a>
          </p>
        </div>
        <div className="flex items-center justify-cetner">
          <img src="/GlowTagline.png" alt="" />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
