import React from "react"
import AddToCalendar from "react-add-to-calendar"
const CalBtn = ({ event }) => {
  return (
    <div
      className="cursor-pointer transition-all duration-200"
      style={{ boxShadow: "" }}
    >
      <AddToCalendar event={event} buttonLabel="Add to calendar" />
    </div>
  )
}

export default CalBtn
